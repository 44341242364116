import { GoogleMap, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { Icon, MapControl } from 'components';
import { CategoryListItem, CoordinateModel, SpotListItem } from 'models';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { LOCATION_DEFAULT } from 'uniforms';
import { handleGetPlace } from 'utils';
import './MapCustom.scss';

type Props = {
    infoBox?: boolean;
    detailSpot?: boolean;
    listSpot?: boolean;
    data?: SpotListItem[];
    categoryList?: CategoryListItem[];
    paramSpotType?: any;
    paramSpotCategory?: any;
    previousLocation?: CoordinateModel;
    onOpenInfoDetail?: (item: SpotListItem) => void;
};

const containerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
};

const defaultMapOptions = {
    disableDefaultUI: true,
};

const MapCustom = forwardRef((props: Props, ref): JSX.Element => {
    const {
        data = [],
        detailSpot = false,
        listSpot = false,
        categoryList = [],
        onOpenInfoDetail,
        previousLocation = LOCATION_DEFAULT,
    } = props;

    const [currentCoordinate, setCurrentCoordinate] = useState<CoordinateModel>(LOCATION_DEFAULT);
    const [currentMarkerVisible, setCurrentMarkerVisible] = useState<boolean>(false);
    const [map, setMap] = useState<any>(null);
    const [dataSpotList, setDataSpotList] = useState<SpotListItem[]>(data);
    const refMap = useRef<any>(null);
    const [currentZoom, setCurrentZoom] = useState<number>(
        detailSpot ? 11 : previousLocation?.zoom ? previousLocation?.zoom : 12,
    );

    /**
     * function use in select
     */
    useImperativeHandle(ref, () => ({
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        getMap() {
            return refMap?.current?.state?.map;
        },
    }));

    /**
     * handle load map
     */
    const handleLoadMap = (map): void => {
        setMap(map);
    };

    /**
     * handle on unmount map
     */
    const handleUnmountMap = (): void => {
        setMap(null);
    };

    /**
     * handle get current location
     */
    const handleGetCurrentLocation = useCallback(async () => {
        if (navigator.geolocation) {
            navigator?.geolocation.getCurrentPosition(async ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                let des = await handleGetPlace(lat, lng);
                setCurrentCoordinate({ coordinates: pos, description: des });
            });
        }
    }, []);

    const handleGoToCurrentLocation = useCallback(async () => {
        if (navigator.geolocation) {
            navigator?.geolocation.getCurrentPosition(async ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                let des = await handleGetPlace(lat, lng);
                map?.panTo({ lat: lat, lng: lng });
                setCurrentCoordinate({ coordinates: pos, description: des });
                setCurrentMarkerVisible(true);
                setCurrentZoom(17);
                // alert(currentZoom);
            });
        }
    }, [map]);

    // useEffect(() => {
    // alert(paramSpotType);
    // alert(paramSpotCategory);
    // if (!detailSpot) {
    //     handleGetCurrentLocation();
    // }
    // }, [detailSpot]);

    useEffect(() => {
        if (data && data !== dataSpotList) {
            setDataSpotList(data);
            if (data.length > 0 && detailSpot) {
                setCurrentCoordinate({
                    coordinates: { lat: data[0].latitude, lng: data[0].longitude },
                });
            }
        }
    }, [data]);

    useEffect(() => {
        if (!detailSpot && previousLocation?.coordinates?.lat && previousLocation?.coordinates?.lng) {
            setCurrentCoordinate(previousLocation);
        }
    }, [previousLocation]);

    return useMemo(
        () => (
            <GoogleMap
                ref={refMap}
                mapContainerStyle={containerStyle}
                // zoom={detailSpot ? 11 : previousLocation?.zoom ? previousLocation?.zoom : 12}
                zoom={currentZoom}
                center={currentCoordinate.coordinates}
                options={defaultMapOptions}
                onLoad={handleLoadMap}
                onUnmount={handleUnmountMap}>
                <div className="control">
                    {dataSpotList &&
                        dataSpotList.length > 0 &&
                        dataSpotList.map(item => {
                            const displayColor = categoryList.find(
                                x => x.category_id === item.spot_category_id,
                            )?.display_color;
                            if (item.latitude && item.longitude) {
                                return (
                                    <OverlayViewF
                                        key={item.spot_id}
                                        getPixelPositionOffset={(width, height): { x: number; y: number } => ({
                                            x: -(width / 2),
                                            y: -height / 2,
                                        })}
                                        position={{
                                            lat: parseFloat(String(item.latitude)),
                                            lng: parseFloat(String(item.longitude)),
                                        }}
                                        zIndex={100}
                                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                                        <div
                                            className="custom-marker cursor-pointer"
                                            onClick={(): void => onOpenInfoDetail && onOpenInfoDetail(item)}>
                                            <Icon.CustomMarker
                                                color={item.spot_clear_status > 0 ? '#ccc' : displayColor}
                                            />
                                            <img className="marker-photo" src={item.photo_url} alt={item.spot_title} />
                                        </div>
                                    </OverlayViewF>
                                );
                            }
                            return <></>;
                        })}
                    {currentMarkerVisible && (
                        <OverlayViewF
                            getPixelPositionOffset={(width, height): { x: number; y: number } => ({
                                x: -(width / 2),
                                y: -height / 2,
                            })}
                            position={currentCoordinate.coordinates}
                            zIndex={100}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                            <div className="custom-marker">
                                <Icon.MarkerIcon width="40" height="47" />
                            </div>
                        </OverlayViewF>
                    )}
                    <MapControl
                        position="BLOCK_END_INLINE_END"
                        className="full-screen map-control-position-css"
                        listSpot={listSpot}
                        onPanToCurrentLocation={handleGoToCurrentLocation}
                    />
                </div>
            </GoogleMap>
        ),
        [
            currentZoom,
            currentCoordinate.coordinates,
            dataSpotList,
            currentMarkerVisible,
            listSpot,
            handleGoToCurrentLocation,
            categoryList,
            onOpenInfoDetail,
        ],
    );
});
export default MapCustom;
