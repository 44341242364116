import { Auth } from 'aws-amplify';
import axios from 'axios';
import { Environments } from 'configs';
import { ResponseType } from 'models';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';
import { MESSAGES, STATUS_API, STATUS_CODE_API } from 'uniforms';
import KEY_STORAGE from 'uniforms/key-storage';

/**
 * get Path
 * @param {path} string
 * @param {pathParams} object
 * @param {varParams} object
 * @return string
 */
const replacePathParams = (path: string, pathParams: object, varParams?: object): string => {
    if (!path) return '/';
    let pathToGo = path;
    if (pathParams) {
        Object.keys(pathParams).forEach(param => {
            pathToGo = pathToGo.replace(`:${param}`, pathParams[param]);
        });
    }
    if (varParams) {
        const urlParams = Object.keys(varParams).map(param => {
            return `${param}=${varParams[param]}`;
        });
        pathToGo += `?${urlParams.join('&')}`;
    }

    return pathToGo;
};

/**
 * show loading
 * @param visible
 * @return void
 */
const showLoading = (visible = true): void => {
    if (visible) {
        const spiner = document.getElementById('loading');
        spiner?.classList.add('show');
    }
};

/**
 * hide loading
 * @return void
 */
const hideLoading = (): void => {
    const spiner = document.getElementById('loading');
    spiner?.classList.remove('show');
};

/**
 * handle get place from location
 */
const handleGetPlace = async (lat: number, lng: number, noFormatted = false): Promise<string> => {
    if (!lat || !lng) {
        return '';
    }
    const res = await axios.get(
        // eslint-disable-next-line max-len
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCyJ0oIU276EOSfDTMCfVpm2oz_e_oh76Y`,
    );
    if (res?.data?.results && res?.data?.results.length && res?.data?.status === 'OK' && !noFormatted) {
        return res.data.results[0].formatted_address;
    }
    if (res?.data?.results && res?.data?.results.length && res?.data?.status === 'OK' && noFormatted) {
        return res.data.results[0];
    }
    return '';
};

/**
 * Check null or empty
 * @param {object} val
 * @return boolean
 */
function isEmptyOrNull(val: any): boolean {
    return !!(
        val === undefined ||
        val == null ||
        val.length <= 0 ||
        (typeof val === 'object' && Object.keys(val).length === 0)
    );
}

/**
 * Handle Call back Api
 * @param successFn
 * @param failureFn
 * @param response ResponseType
 */
const HandleCallbackApi = async <T>(successFn, failureFn, response: ResponseType<T> | undefined): Promise<void> => {
    if (response?.error_code === STATUS_API.SUCCESS) {
        successFn && successFn(response);
    } else {
        failureFn && failureFn(response);
    }
};

/**
 * number with commas
 * @param num string
 * @returns string
 */
const NumberWithCommas = (num: string): string => {
    if (!num) return '0';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * get cookie by name
 * @param name
 * @returns
 */
const getCookie = (name: string): string | undefined => {
    let cookie = {};
    document.cookie.split(';').forEach(function (el) {
        let [k, v] = el.split('=');
        cookie[k.trim()] = v;
    });
    return cookie[name];
};

/**
 * remove cookie by name
 * @param name
 * @returns
 */
const removeCookie = (name: string): boolean | undefined => {
    document.cookie = name + "=; max-age=0";
    return true;
};

/**
 * get profile user
 * @param profile
 * @returns
 */
const getProfileUser = () => {
    let data = localStorage.getItem('profile');
    if (data) {
        return JSON.parse(data);
    }
    return undefined;
};

/**
 * replace error message
 * @param message
 * @param field_name
 * @returns
 */
const showErrorMessage = (message: string, field_name: string) => {
    return message?.replaceAll('{{field_name}}', field_name);
};
/**
 * replace error message
 * @param lat1
 * @param lon1
 * @param lat2
 * @param lon2
 * @returns number
 */
const measure = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    let R = 6378.137;
    let dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180;
    let dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180;
    let a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return d * 1000;
};

/**
 * handle resize image
 * @param file
 * @param extension
 */
const handleResizeImage = (file, extension) => {
    return new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            extension,
            100,
            0,
            uri => {
                resolve(uri);
            },
            'base64',
        );
    });
};

/**
 * handle resize upload photo image
 * @param file
 * @param extension
 */
const handleResizeUploadPhotoImage = (file, extension) => {
    return new Promise(resolve => {
        Resizer.imageFileResizer(
            file,
            900,
            900,
            extension,
            100,
            0,
            uri => {
                resolve(uri);
            },
            'base64',
        );
    });
};

/**
 * get extension of image
 * @param fileName
 * @returns
 */
const getExtensionImage = (fileName: string): string => {
    return fileName.split('.').pop() || '';
};

/**
 * validate value of object
 * @param obj
 * @returns
 */
const validateObject = (obj): boolean => {
    if (Object.keys(obj).length === 0) {
        return false;
    } else {
        for (const i in obj) {
            if (isEmptyOrNull(obj[i])) {
                return false;
            }
            if (typeof obj[i] === 'number' && obj[i] === 0) {
                return false;
            }
        }
        return true;
    }
};

/**
 * Convert date time to format type
 * @param Date someDate
 * @param formatType someDate
 * @return string
 */
const formatDate = (someDate, formatType): string => {
    if (!formatType || !someDate) return '';
    return moment(someDate, formatType).format(formatType);
};

/**
 * get Line URL
 * @param page
 * @param data
 * @returns
 */
const getLineUrl = (page: string, data: string[]): string => {
    let url = Environments.lineLink + page;
    if (data.length === 0) {
        return '';
    } else {
        data.map(item => {
            url += '&' + item;
        });
        return url;
    }
};

const mapAnswerArrayData = values => {
    const answerArray: string[] = [];
    if (values.spot_answer_choice1) {
        answerArray.push(values.spot_answer_choice1);
    }
    if (values.spot_answer_choice2) {
        answerArray.push(values.spot_answer_choice2);
    }
    if (values.spot_answer_choice3) {
        answerArray.push(values.spot_answer_choice3);
    }
    if (values.spot_answer_choice4) {
        answerArray.push(values.spot_answer_choice4);
    }

    let obj: any = {};
    for (let index = 0; index < 4; index++) {
        if (answerArray[index]) {
            obj[`spot_answer_choice${index + 1}`] = answerArray[index].trim();
        } else {
            obj[`spot_answer_choice${index + 1}`] = '';
        }
    }

    return obj;
};

const toCardinal = (num: number): string => {
    var ones = num % 10;
    var tens = num % 100;

    if (tens < 11 || tens > 13) {
        switch (ones) {
            case 1:
                return num + 'st';
            case 2:
                return num + 'nd';
            case 3:
                return num + 'rd';
        }
    }

    return num + 'th';
};

const call = () => {
    const url =
        // eslint-disable-next-line max-len
        'https://dev-lae-users.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?client_id=5grvaco9bv84n80389v7mf1gst&response_type=token&scope=openid+profile&redirect_uri=https%3A%2F%2Fd1z1afcki2ymx1.cloudfront.net%3Fcognito%3Dtrue';
    window.location.href = url;
};

export {
    replacePathParams,
    showLoading,
    hideLoading,
    handleGetPlace,
    isEmptyOrNull,
    HandleCallbackApi,
    NumberWithCommas,
    getCookie,
    removeCookie,
    getProfileUser,
    showErrorMessage,
    measure,
    handleResizeImage,
    getExtensionImage,
    validateObject,
    formatDate,
    getLineUrl,
    call,
    mapAnswerArrayData,
    toCardinal,
    handleResizeUploadPhotoImage,
};
