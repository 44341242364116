import { fetcherApi } from 'api/fetcher';
import {
    StageParams,
    StageActionClearParams,
    StageActionDetailParams,
    StageActionClearResponse,
    StageResponse,
    StageActionDetailResponse,
    UserStageUnclearParams,
    UserStageUnclearResponse,
} from 'models';
import { APILIST } from 'uniforms';
import { HandleCallbackApi } from 'utils';

export async function getStageService(params: StageParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<StageParams, StageResponse>(body, APILIST.USER.STAGE, 'GET');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postStageActionClearService(params: StageActionClearParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const tempBody = { ...body, noLoading: true };
    const response = await fetcherApi<StageActionClearParams, StageActionClearResponse>(tempBody, APILIST.USER.STAGE_ACTION_CLEAR, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function getStageActionDetailService(params: StageActionDetailParams): Promise<void> {
    const { successCallback, failureCallback, ...body } = params;
    const response = await fetcherApi<StageActionDetailParams, StageActionDetailResponse>(body, APILIST.STAGE.ACTION, 'GET');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}

export async function postUserStageUnclearService(params: UserStageUnclearParams): Promise<void> {
    const { successCallback, failureCallback } = params;
    const tempBody = { noLoading: true };
    const response = await fetcherApi<UserStageUnclearParams, UserStageUnclearResponse>(tempBody, APILIST.USER.STAGE_UNCLEAR, 'POST');
    HandleCallbackApi(successCallback, failureCallback, { ...response });
}
