import { defaultPhoto } from 'assets/images';
import { AvatarFrame, Icon } from 'components';
import { CoordinateModel, SpotLikeRegistParams, StageActionItem } from 'models';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router';
import { PATH } from 'uniforms';
import { replacePathParams } from 'utils';
import { Environments } from 'configs';
import './StageAction.scss';

const sizeAvatarFrame = {
    layer1: 24,
    layer2: 22,
    avatar: 18,
};

type Props = {
    stageActionItem: StageActionItem;
    onGoToDetail: () => void;
};

/**
 * my spot item
 * @returns
 */
const StageAction = (props: Props): JSX.Element => {
    const { stageActionItem, onGoToDetail} = props;
    const [location, setLocation] = useState<CoordinateModel>();
    const navigate = useNavigate();
    const comment = useMemo(() => {
        return stageActionItem?.action_comment?.replaceAll('\\n', '\n');
    }, [stageActionItem]);

    /**
     * handle get location
     */
    const handleGetLocation = useCallback(async (latitude, longitude) => {
        if (latitude && longitude) {
            // const res = await handleGetPlace(latitude, longitude);
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        } else {
            setLocation({ coordinates: { lat: latitude, lng: longitude }, description: '' });
        }
    }, []);

    // const handleGoToUserDetailPage = useCallback(
    //    (id: string) => (): void => {
    //        navigate(replacePathParams(PATH.USER.DETAIL, { id: id }));
    //    },
    //    [navigate],
    // );

    useEffect(() => {
        handleGetLocation(stageActionItem?.action_latitude, stageActionItem?.action_longitude);
    }, [stageActionItem?.action_latitude, stageActionItem?.action_longitude]);

    return useMemo(
        () =>
            stageActionItem && (
                <div className="spot-item-wrapper" onClick={onGoToDetail}>
                    <div className="spot-item-border">
                        <div className="d-flex gap-2 info-component">
                            {/*
                            <div className="img-status-component col-4 col-md-3">
                                <LazyLoadImage
                                    className="image-spot mt-2"
                                    src={Environments.baseUrlWeb + stageActionItem.action_photo_url || defaultPhoto}
                                    alt=""
                                    // onClick={onGoToDetail}
                                />
                            </div>
                            */}
                            <div className="info-component col-12">
                                <div className="middle-info spot-item-detail d-flex flex-column">
                                    <div className="spot-title">
                                        {stageActionItem.action_title}
                                    </div>
                                    <div className="spot-comment">
                                        {comment}
                                    </div>
                                    {/*
                                    <Row className="icon-wrapper">
                                        <Col>                                        
                                            <div className="spot-clear-way">
                                                <div className="spot-clear-way-text">{stageActionItem.action_clear_way === 1 ? "AR" : "QR"}</div>
                                            </div>
                                        </Col>
                                        <Col className="icon p-0" xs={6}>
                                            <div className="icon-min-width d-flex align-items-center">
                                                <Icon.Location width="20" height="20" />
                                            </div>
                                            <div className="spot-icon text-overflow-ellipsis pe-2 pe-md-5">
                                                {location?.description}
                                            </div>
                                        </Col>
                                    </Row>
                                    */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        [stageActionItem, onGoToDetail, comment],
    );
};

export default StageAction;
