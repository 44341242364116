/**
 * api lists
 */
export const APILIST = {
    MYSPOT: {
        LIST: 'myspot/list',
        DETAIL: 'myspot/detail',
    },
    USER: {
        LIST: 'user/list',
        DETAIL: 'user/detail',
        RANK: 'user/rank',
        STATUS: 'user/status',
        RELATION: 'user/relation',
        CLEAR: 'user/clear',
        STAGE: 'user/stage',
        STAGE_ACTION_CLEAR: 'user/stage/action/clear',
        STAGE_UNCLEAR: 'user/stage/unclear',
        RICHMENU: 'user/richmenu',
        EVENT: 'user/event',
        POINT: '/user/point',
        BOTTLE: '/user/bottle',
    },
    STAGE: {
        ACTION: 'stage/action',
    },
    CATEGORY: 'category',
    SPOT: {
        LIST: 'spot/list',
        DETAIL: 'spot/detail',
        COMMENT: 'spot/comment',
        LIKE: 'spot/like',
        VIEW: 'spot/view',
        CLEAR: 'spot/clear',
        UNCLEAR: 'spot/unclear',
    },
    BOTTLE: {
        USER_STATISTICS: '/user/bottle/statistics',
        STATISTICS: '/bottle/statistics',
        MESSAGE: '/bottle/message',
        ALL: '/bottle/statistics/all',
    },
};
