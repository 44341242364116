import { ConfirmModal, FrameCover, FrameItem, Icon, Input, Modal, TitleField, YesOrNoBtn } from 'components';
import { Formik, FormikProps } from 'formik';
import { useCategory, useCommon } from 'hooks';
import { CategoryListItem, CoordinateModel, MySpotDetailItem, SpotCommentListItem, UserRank } from 'models';
import QRCode from 'qrcode.react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
    ALERT_STATUS,
    CATEGORY_DATA,
    CATEGORY_OR_SPOT_TYPE,
    MESSAGES,
    SPOT_CLEAR_TYPE,
    SPOT_CLEAR_TYPE_DISPLAY,
} from 'uniforms';
import { getExtensionImage, getLineUrl, handleResizeImage, mapAnswerArrayData, showErrorMessage } from 'utils';
import * as Yup from 'yup';
import './MySpotRegistDetail.scss';
import { Environments } from 'configs';

/**
 * Props type
 */
type Props = {
    id?: string;
    onSubmitForm: (item: FormMySpotRegistDetail, image: any) => void;
    data?: MySpotDetailItem;
    dataForm?: FormMySpotRegistDetail;
    image?: any;
    onNextStep: (data?: FormMySpotRegistDetail, image?: string) => void;
    currentLocation?: CoordinateModel;
    seeMoreFlg?: boolean;
    spotComment?: SpotCommentListItem[];
    userRank?: UserRank;
    onDeleteSpot?: () => void;
    onUnclearSpot?: () => void;
};

type FormMySpotRegistDetail = {
    spot_title?: string;
    spot_comment?: string;
    spot_clear_distance?: number;
    radius?: number;
    is_explanation_required?: boolean;
    explanation?: string;
    spot_comment_after_clear?: string;
    description?: string;
    spot_category_display_name?: string;
    spot_category_id?: string;
    spot_type_id?: string;
    spot_type_display_name?: string;
    spot_clear_way?: number;
    spot_points?: number;
    spot_clear_key?: string;
    is_gps_required?: boolean;
    can_retry?: boolean;
    is_photo_gps_required?: boolean;
    is_screen_transition_required?: boolean;
    screen_transition_url?: string;
    photo_url?: string;
    spot_answer_choice1?: string;
    spot_answer_choice2?: string;
    spot_answer_choice3?: string;
    spot_answer_choice4?: string;
};

/**
 * my spot registration detail component
 * @returns
 */
const MySpotRegistDetail = forwardRef((props: Props, ref): JSX.Element => {
    const { id, onSubmitForm, data, onNextStep, currentLocation, dataForm, image, onDeleteSpot, onUnclearSpot } = props;
    const formikRef = useRef<FormikProps<FormMySpotRegistDetail>>(null);
    const { getCategoryList } = useCategory();
    const { showAlert } = useCommon();
    const [spotCategory, setSpotCategory] = useState<CategoryListItem>({
        category_id: '',
        category_display_name: '',
    });
    const [categoryList, setCategoryList] = useState<CategoryListItem[]>([]);
    const [spotType, setSpotType] = useState<CategoryListItem>({
        category_type_id: '',
        category_type_display_name: '',
    });
    const [imageSpot, setImageSpot] = useState<any>(image);
    const [spotClearType, setSpotClearType] = useState<{ label: string; value: number }>({ label: '', value: 0 });
    const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
    const [isShowDeleteConfirmModal, setIsShowDeleteConfirmModal] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        handleSubmitForm(): void {
            return handleSubmitData(formikRef?.current?.values);
        },
    }));

    const comment = useMemo(() => {
        return data?.spot_comment;
    }, [data]);

    const comment_after_clear = useMemo(() => {
        return data?.spot_comment_after_clear;
    }, [data]);

    const explanation = useMemo(() => {
        return data?.explanation;
    }, [data]);

    /**
     * initital value of form
     */
    const INITIAL_VALUES: FormMySpotRegistDetail = useMemo(
        () => ({
            ...data,
            ...dataForm,
            spot_comment: (dataForm?.spot_comment || comment || '').replaceAll('\\n', '\n'),
            spot_comment_after_clear: (dataForm?.spot_comment_after_clear || comment_after_clear || '').replaceAll(
                '\\n',
                '\n',
            ),
            explanation: (dataForm?.explanation || explanation || '').replaceAll('\\n', '\n'),
            description: currentLocation?.description || '',
            spot_clear_distance: data?.radius || dataForm?.radius || 30,
            spot_clear_way: data?.spot_clear_way || dataForm?.spot_clear_way || 0,
            spot_points: data?.spot_points || dataForm?.spot_points || 10,
            is_photo_gps_required: data?.is_photo_gps_required || dataForm?.is_photo_gps_required || false,
            can_retry: data?.can_retry || dataForm?.can_retry || false,
            is_gps_required: data?.is_gps_required || dataForm?.is_gps_required || false,
            is_screen_transition_required:
                data?.is_screen_transition_required || dataForm?.is_screen_transition_required || false,
            photo_url: image || data?.photo_url || '',
        }),
        [data, dataForm, comment, comment_after_clear, currentLocation?.description, image],
    );

    /**
     * SCHEMA of Form
     */
    const SCHEMA = useMemo(
        () =>
            Yup.object().shape({
                spot_title: Yup.string().trim().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'タイトル')),
                spot_comment: Yup.string()
                    .trim()
                    .required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'ミッションの説明')),
                description: Yup.string().when(['is_gps_required', 'is_photo_gps_required'], {
                    is: (is_gps_required, is_photo_gps_required) => is_gps_required || is_photo_gps_required,
                    then: Yup.string().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, '位置情報')),
                }),
                spot_category_display_name: Yup.string().required(
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'カテゴリ'),
                ),
                spot_type_display_name: Yup.string().required(
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'ミッションタイプ '),
                ),
                photo_url: Yup.string().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, '写真')),
                spot_clear_way: Yup.number().required(
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'ミッションクリア方法 '),
                ),
                is_gps_required: Yup.boolean().required(showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'GPSチェック')),
                explanation: Yup.string()
                    .trim()
                    .when('is_explanation_required', {
                        is: true,
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, '解説')),
                    }),
                spot_clear_distance: Yup.string().when(['is_gps_required', 'is_photo_gps_required'], {
                    is: (is_gps_required, is_photo_gps_required) => is_gps_required || is_photo_gps_required,
                    then: Yup.string().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'GPS許容半径 [メートル]')),
                }),
                is_screen_transition_required: Yup.boolean().required(
                    showErrorMessage(MESSAGES.CHOOSE_REQUIRED, 'クリア後の画面遷移'),
                ),
                screen_transition_url: Yup.string()
                    .trim()
                    .when('is_screen_transition_required', {
                        is: true,
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, 'クリア後の画面遷移')),
                    }),
                spot_clear_key: Yup.string()
                    .trim()
                    .when('spot_clear_way', {
                        is: val => [SPOT_CLEAR_TYPE.WORD.value, SPOT_CLEAR_TYPE.CHOICE.value].includes(val),
                        then: Yup.string().required(showErrorMessage(MESSAGES.CHECK_REQUIRED, '答え')),
                    }),
            }),
        [],
    );

    /**
     * handle submit form
     */
    const handleSubmitData = useCallback(
        values => {
            const objAnswer = mapAnswerArrayData(values);
            if (
                values.spot_clear_way === SPOT_CLEAR_TYPE.CHOICE.value &&
                !objAnswer.spot_answer_choice1 &&
                !objAnswer.spot_answer_choice2 &&
                !objAnswer.spot_answer_choice3 &&
                !objAnswer.spot_answer_choice4
            ) {
                formikRef.current?.setFieldError(
                    'spot_answer_choice1',
                    showErrorMessage(MESSAGES.CHECK_REQUIRED, '選択肢'),
                );
                return;
            }
            delete values.spot_answer_choice1;
            delete values.spot_answer_choice2;
            delete values.spot_answer_choice3;
            delete values.spot_answer_choice4;
            const params = {
                ...values,
                ...objAnswer,
                spot_title: values.spot_title.trim(),
                spot_comment: values.spot_comment.trim(),
                explanation: values.explanation ? values.explanation.trim() : '',
                screen_transition_url: values.screen_transition_url ? values.screen_transition_url.trim() : '',
                spot_clear_key: values.spot_clear_key ? values.spot_clear_key.trim() : '',
            };
            onSubmitForm && onSubmitForm(params, imageSpot);
        },
        [imageSpot, onSubmitForm],
    );

    /**
     * handle choose category
     */
    const handleChooseCategory = useCallback(
        (item: CategoryListItem) => (): void => {
            if (item.category_id !== spotCategory?.category_id) {
                formikRef?.current?.setFieldValue('spot_category_display_name', item.category_display_name);
                formikRef?.current?.setFieldValue('spot_category_id', item.category_id);
                setSpotCategory(item);
            } else {
                formikRef?.current?.setFieldValue('spot_category_display_name', '');
                formikRef?.current?.setFieldValue('spot_category_id', '');
                setSpotCategory({ category_id: '', category_display_name: '' });
            }

            formikRef?.current?.setFieldValue('spot_type_display_name', '');
            formikRef?.current?.setFieldValue('spot_type_id', '');
            setSpotType({ category_id: '', category_display_name: '' });
        },
        [spotCategory?.category_id],
    );

    /**
     * handle choose spot type
     */
    const handleChooseSpotType = useCallback(
        (item: CategoryListItem) => (): void => {
            if (item.category_id !== spotType?.category_id) {
                formikRef?.current?.setFieldValue('spot_type_display_name', item.category_display_name);
                formikRef?.current?.setFieldValue('spot_type_id', item.category_id);
                setSpotType(item);
            } else {
                formikRef?.current?.setFieldValue('spot_type_display_name', '');
                formikRef?.current?.setFieldValue('spot_type_id', '');
                setSpotType({ category_id: '', category_display_name: '' });
            }
        },
        [spotType?.category_id],
    );

    const handleShowDeleteSpotModal = useCallback(() => {
        setIsShowDeleteModal(true);
    }, []);

    const handleShowDeleteSpotModalConfirm = useCallback(() => {
        setIsShowDeleteConfirmModal(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsShowDeleteModal(false);
        setIsShowDeleteConfirmModal(false);
    }, []);

    /**
     * Handle upload image
     * @params e
     * @returns void
     */
    const handleUploadImage = useCallback(async e => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const extension = getExtensionImage(e.target.files[0].name).toLocaleUpperCase();
            const image = await handleResizeImage(file, extension);
            setImageSpot(image);
            formikRef?.current?.setFieldValue('photo_url', image);
        }
    }, []);

    /**
     * handle choose spot clear type
     */
    const handleChooseSpotClearType = useCallback(
        item => (): void => {
            if (item !== spotClearType.value) {
                setSpotClearType(item);
                formikRef?.current?.setFieldValue('spot_clear_way', item.value);
                if (spotClearType.value != SPOT_CLEAR_TYPE.IMAGE.value) {
                    formikRef?.current?.setFieldValue('is_photo_gps_required', false);
                }
            } else {
                setSpotClearType({ label: '', value: 0 });
                formikRef?.current?.setFieldValue('spot_clear_way', 0);
            }
        },
        [spotClearType],
    );

    /**
     * handle choose Yes or no
     */
    const handleChooseYesOrNoRequired = useCallback((key: string, item) => {
        formikRef?.current?.setFieldValue(key, item);
        switch (key) {
            case 'is_screen_transition_required':
                // 画面遷移が不要な場合は画面遷移先URLを空にする
                if (item === false) {
                    formikRef?.current?.setFieldValue('screen_transition_url', '');
                }
                break;
            case 'is_explanation_required':
                // 画面遷移が不要な場合は画面遷移先URLを空にする
                if (item === false) {
                    formikRef?.current?.setFieldValue('explanation', '');
                }
                break;
            /* 
            case 'is_photo_gps_required':
                if (item === true) {
                    formikRef?.current?.setFieldValue('can_retry', true);
                }
                break;
            case 'can_retry':
                const isPhotoGpsRequired = formikRef?.current?.values.is_photo_gps_required;
                if (isPhotoGpsRequired === true) {
                    formikRef?.current?.setFieldValue('can_retry', true);
                }
                break;
            */
            default:
                break;
        }
    }, []);

    /**
     * handle choose location
     */
    const handleChooseLocation = useCallback(() => {
        onNextStep(formikRef?.current?.values, imageSpot);
    }, [imageSpot, onNextStep]);

    useEffect(() => {
        if (dataForm && dataForm.spot_category_display_name) {
            setSpotCategory({
                category_display_name: dataForm.spot_category_display_name,
                category_id: dataForm.spot_category_id,
            });
        } else if (data && data.spot_category_id) {
            setSpotCategory({
                category_display_name: data.spot_category_display_name,
                category_id: data.spot_category_id,
            });
        }
    }, [data, data?.spot_category_display_name, dataForm]);

    useEffect(() => {
        if (dataForm && dataForm.spot_type_display_name) {
            setSpotType({
                category_display_name: dataForm?.spot_category_display_name,
                category_id: dataForm?.spot_type_id,
            });
        } else if (data && data.spot_type_id) {
            setSpotType({
                category_display_name: data?.spot_category_display_name,
                category_id: data.spot_type_id,
            });
        }
    }, [data, data?.spot_type_display_name, dataForm]);

    useEffect(() => {
        if (image) {
            setImageSpot(image);
        } else if (data?.photo_url) {
            setImageSpot(data.photo_url);
        }
    }, [data?.photo_url, image]);

    useEffect(() => {
        if (dataForm && dataForm.spot_clear_way) {
            setSpotClearType({
                value: dataForm.spot_clear_way,
                label:
                    dataForm.spot_clear_way === SPOT_CLEAR_TYPE.AR.value
                        ? SPOT_CLEAR_TYPE.AR.label
                        : dataForm.spot_clear_way === SPOT_CLEAR_TYPE.QR.value
                        ? SPOT_CLEAR_TYPE.QR.label
                        : dataForm.spot_clear_way === SPOT_CLEAR_TYPE.WORD.value
                        ? SPOT_CLEAR_TYPE.WORD.label
                        : dataForm.spot_clear_way === SPOT_CLEAR_TYPE.BUTTON.value
                        ? SPOT_CLEAR_TYPE.BUTTON.label
                        : SPOT_CLEAR_TYPE.CARD.label,
            });
        } else if (data && data.spot_clear_way) {
            setSpotClearType({
                value: data.spot_clear_way,
                label:
                    data.spot_clear_way === SPOT_CLEAR_TYPE.AR.value
                        ? SPOT_CLEAR_TYPE.AR.label
                        : data.spot_clear_way === SPOT_CLEAR_TYPE.QR.value
                        ? SPOT_CLEAR_TYPE.QR.label
                        : data.spot_clear_way === SPOT_CLEAR_TYPE.WORD.value
                        ? SPOT_CLEAR_TYPE.WORD.label
                        : data.spot_clear_way === SPOT_CLEAR_TYPE.BUTTON.value
                        ? SPOT_CLEAR_TYPE.BUTTON.label
                        : SPOT_CLEAR_TYPE.CARD.label,
            });
        }
    }, [data?.spot_clear_way, dataForm]);

    useEffect(() => {
        getCategoryList({
            successCallback(data) {
                setCategoryList(data.result_records.category_list);
            },
            failureCallback(data) {
                showAlert(MESSAGES.COMMON_ERROR, ALERT_STATUS.ERROR);
            },
        });
    }, []);

    return useMemo(
        () => (
            <div className="my-spot-regist-detail-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={INITIAL_VALUES}
                    enableReinitialize={true}
                    validationSchema={SCHEMA}
                    onSubmit={handleSubmitData}>
                    {({ values, errors, handleSubmit, handleChange, handleBlur, touched }): JSX.Element => (
                        <form className="form-container" onSubmit={handleSubmit}>
                            <div className="form-submit-border">
                                <div className="mb-3">
                                    <FrameCover>
                                        <>
                                            <div className="my-spot-photo">
                                                <div>
                                                    <label
                                                        htmlFor="file-image-upload"
                                                        className="custom-file-image-upload"
                                                        style={{
                                                            border: imageSpot && 'none',
                                                            padding: imageSpot && 0,
                                                        }}>
                                                        {imageSpot ? (
                                                            <img
                                                                className="image-spot"
                                                                height={160}
                                                                width={160}
                                                                src={imageSpot}
                                                                alt="avatar"
                                                            />
                                                        ) : (
                                                            <>
                                                                <Icon.Plus />
                                                                <div className="mt-2 text-sm">写真 PHOTO</div>
                                                            </>
                                                        )}
                                                    </label>
                                                    <Input
                                                        type="file"
                                                        id="file-image-upload"
                                                        accept="image/gif,image/jpeg,image/png"
                                                        key="upload-image"
                                                        name="image-upload-btn"
                                                        className="d-none"
                                                        onChange={handleUploadImage}
                                                    />
                                                    {!!errors.photo_url && !!touched.photo_url && (
                                                        <div className="text-error">{errors.photo_url}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="my-spot-input">
                                                <TitleField title="タイトル" />
                                                <Input
                                                    type="text"
                                                    name="spot_title"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.spot_title}
                                                    error={!!errors.spot_title && !!touched.spot_title}
                                                    msgError={errors.spot_title}
                                                />
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="ミッションの説明" />
                                                <div className="input-container">
                                                    <textarea
                                                        className={`${errors.spot_comment ? 'input-error' : ''}`}
                                                        name="spot_comment"
                                                        rows={7}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.spot_comment}
                                                        style={{ resize: 'none' }}
                                                    />
                                                    {!!errors.spot_comment && !!touched.spot_comment && (
                                                        <div className="text-error">{errors.spot_comment}</div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="解説" />
                                                <YesOrNoBtn
                                                    className="mb-12"
                                                    value={values.is_explanation_required}
                                                    onYesSubmit={(): void => {
                                                        handleChooseYesOrNoRequired('is_explanation_required', true);
                                                    }}
                                                    onNoSubmit={(): void => {
                                                        handleChooseYesOrNoRequired('is_explanation_required', false);
                                                    }}
                                                />
                                                {values.is_explanation_required && (
                                                    <div className="input-container">
                                                        <textarea
                                                            className={`${errors.explanation ? 'input-error' : ''}`}
                                                            name="explanation"
                                                            rows={7}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.explanation}
                                                            style={{ resize: 'none' }}
                                                        />
                                                        {!!errors.explanation && !!touched.explanation && (
                                                            <div className="text-error">{errors.explanation}</div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="位置情報" />
                                                <div onClick={handleChooseLocation}>
                                                    <Input
                                                        mapInput={true}
                                                        value={(values.description || '')
                                                            .replace(/^日本、/g, '')
                                                            .replace(/^〒[0-9]{3}-[0-9]{4}/g, '')}
                                                        name="description"
                                                        error={!!errors.description && !!touched.description}
                                                        msgError={errors.description}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <TitleField title="カテゴリ" />
                                                <div className="myspot-category-list pb-3">
                                                    {categoryList.map(item => {
                                                        if (
                                                            item.category_type_id ===
                                                            CATEGORY_OR_SPOT_TYPE.SPOT_CATEGORY
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={item.category_id}
                                                                    className={`${
                                                                        spotCategory.category_id === item.category_id
                                                                            ? 'active-badge'
                                                                            : 'inactive-badge'
                                                                    }`}
                                                                    onClick={handleChooseCategory(item)}>
                                                                    {item.category_display_name}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                {!!errors.spot_category_display_name &&
                                                    !!touched.spot_category_display_name && (
                                                        <div className="text-error">
                                                            {errors.spot_category_display_name}
                                                        </div>
                                                    )}
                                            </div>

                                            <div>
                                                <TitleField title="ミッションタイプ" />
                                                <div className="myspot-category-list pb-3">
                                                    {categoryList.map(item => {
                                                        const spotCategoryId = values.spot_category_id;
                                                        let condition = true;
                                                        if (spotCategoryId) {
                                                            condition = CATEGORY_DATA[spotCategoryId].includes(
                                                                item.category_id,
                                                            );
                                                        }

                                                        if (
                                                            item.category_type_id === CATEGORY_OR_SPOT_TYPE.SPOT_TYPE &&
                                                            condition
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={item.category_id}
                                                                    className={`${
                                                                        spotType?.category_id === item.category_id
                                                                            ? 'active-badge'
                                                                            : 'inactive-badge'
                                                                    }`}
                                                                    onClick={handleChooseSpotType(item)}>
                                                                    {item.category_display_name}
                                                                </div>
                                                            );
                                                        }
                                                    })}
                                                </div>
                                                {!!errors.spot_type_display_name &&
                                                    !!touched.spot_type_display_name && (
                                                        <div className="text-error">
                                                            {errors.spot_type_display_name}
                                                        </div>
                                                    )}
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="ミッションクリア方法" />

                                                <div className="myspot-category-list pb-3">
                                                    {Object.keys(SPOT_CLEAR_TYPE_DISPLAY).map(key => {
                                                        return (
                                                            <div
                                                                key={SPOT_CLEAR_TYPE_DISPLAY[key].value}
                                                                className={`w-100 text-center ${
                                                                    spotClearType.value ===
                                                                    SPOT_CLEAR_TYPE_DISPLAY[key].value
                                                                        ? 'active-badge'
                                                                        : 'inactive-badge'
                                                                }`}
                                                                onClick={handleChooseSpotClearType(
                                                                    SPOT_CLEAR_TYPE_DISPLAY[key],
                                                                )}>
                                                                {SPOT_CLEAR_TYPE_DISPLAY[key].label}
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                                {spotClearType.value === SPOT_CLEAR_TYPE.QR.value && !!id && (
                                                    <div className="myspot-QR-container">
                                                        <div className="myspot-QR-code">
                                                            <QRCode
                                                                value={`${Environments.lineLink}spot/detail/${
                                                                    data?.spot_id
                                                                }?spot_clear_key=${data?.spot_clear_key || ''}`}
                                                                size={240}
                                                                level={'M'}
                                                                includeMargin={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {values.spot_clear_way === 0 && !!touched.spot_clear_way && (
                                                    <div className="text-error">
                                                        {showErrorMessage(
                                                            MESSAGES.CHOOSE_REQUIRED,
                                                            'スポットクリア方法',
                                                        )}
                                                    </div>
                                                )}

                                                {(spotClearType.value === SPOT_CLEAR_TYPE.WORD.value ||
                                                    spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value) && (
                                                    <div className="my-spot-input">
                                                        <TitleField
                                                            title="答え"
                                                            titleNote={
                                                                spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value
                                                                    ? '選択肢の単語と一致する必要があります。'
                                                                    : ''
                                                            }
                                                        />
                                                        <Input
                                                            type="text"
                                                            name="spot_clear_key"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.spot_clear_key}
                                                            error={!!errors.spot_clear_key && !!touched.spot_clear_key}
                                                            msgError={errors.spot_clear_key}
                                                        />
                                                    </div>
                                                )}

                                                {spotClearType.value === SPOT_CLEAR_TYPE.CHOICE.value && (
                                                    <div className="my-spot-input">
                                                        <TitleField
                                                            title="選択肢"
                                                            titleNote="記入がない選択肢は非表示になります。"
                                                        />
                                                        <Input
                                                            type="text"
                                                            name="spot_answer_choice1"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.spot_answer_choice1}
                                                            error={
                                                                !!errors.spot_answer_choice1 &&
                                                                !!touched.spot_answer_choice1
                                                            }
                                                            msgError={errors.spot_answer_choice1}
                                                        />
                                                        <Input
                                                            type="text"
                                                            className="mt-1"
                                                            name="spot_answer_choice2"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.spot_answer_choice2}
                                                            error={
                                                                !!errors.spot_answer_choice2 &&
                                                                !!touched.spot_answer_choice2
                                                            }
                                                            msgError={errors.spot_answer_choice2}
                                                        />
                                                        <Input
                                                            type="text"
                                                            className="mt-1"
                                                            name="spot_answer_choice3"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.spot_answer_choice3}
                                                            error={
                                                                !!errors.spot_answer_choice3 &&
                                                                !!touched.spot_answer_choice3
                                                            }
                                                            msgError={errors.spot_answer_choice3}
                                                        />
                                                        <Input
                                                            type="text"
                                                            className="mt-1"
                                                            name="spot_answer_choice4"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.spot_answer_choice4}
                                                            error={
                                                                !!errors.spot_answer_choice4 &&
                                                                !!touched.spot_answer_choice4
                                                            }
                                                            msgError={errors.spot_answer_choice4}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div>
                                                <TitleField title="スマホの位置情報でクリア判定" />
                                                <div className="myspot-category-list pb-3">
                                                    <YesOrNoBtn
                                                        value={values.is_gps_required}
                                                        onYesSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_gps_required', true);
                                                        }}
                                                        onNoSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('is_gps_required', false);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {spotClearType.value === SPOT_CLEAR_TYPE.IMAGE.value && (
                                                <div>
                                                    <TitleField title="写真の位置情報でクリア判定" />
                                                    <div className="myspot-category-list pb-3">
                                                        <YesOrNoBtn
                                                            value={values.is_photo_gps_required}
                                                            onYesSubmit={(): void => {
                                                                handleChooseYesOrNoRequired(
                                                                    'is_photo_gps_required',
                                                                    true,
                                                                );
                                                            }}
                                                            onNoSubmit={(): void => {
                                                                handleChooseYesOrNoRequired(
                                                                    'is_photo_gps_required',
                                                                    false,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            {(values.is_gps_required || values.is_photo_gps_required) && (
                                                <div className="my-spot-input">
                                                    <TitleField title="GPS許容半径 [メートル]" />
                                                    <Input
                                                        type="number"
                                                        name="spot_clear_distance"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.spot_clear_distance}
                                                        error={
                                                            !!errors.spot_clear_distance &&
                                                            !!touched.spot_clear_distance
                                                        }
                                                        msgError={errors.spot_clear_distance}
                                                    />
                                                </div>
                                            )}

                                            <div className="my-spot-input">
                                                <TitleField title="クリア後の獲得ポイント" />
                                                <Input
                                                    type="number"
                                                    name="spot_points"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    min={0}
                                                    value={values.spot_points}
                                                    error={!!errors.spot_points && !!touched.spot_points}
                                                    msgError={errors.spot_points}
                                                />
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="チャレンジ回数" />
                                                <div className="myspot-category-list pb-3">
                                                    <YesOrNoBtn
                                                        value={values.can_retry}
                                                        yesText="何回でも可"
                                                        noText="1回のみ"
                                                        onYesSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('can_retry', true);
                                                        }}
                                                        onNoSubmit={(): void => {
                                                            handleChooseYesOrNoRequired('can_retry', false);
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="my-spot-input">
                                                <TitleField title="クリア後の画面遷移" />
                                                <div className="myspot-category-list pb-3">
                                                    <YesOrNoBtn
                                                        value={values.is_screen_transition_required}
                                                        onYesSubmit={(): void => {
                                                            handleChooseYesOrNoRequired(
                                                                'is_screen_transition_required',
                                                                true,
                                                            );
                                                        }}
                                                        onNoSubmit={(): void => {
                                                            handleChooseYesOrNoRequired(
                                                                'is_screen_transition_required',
                                                                false,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {values.is_screen_transition_required && (
                                                <Input
                                                    type="text"
                                                    name="screen_transition_url"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.screen_transition_url}
                                                    error={!!errors.screen_transition_url}
                                                    msgError={errors.screen_transition_url}
                                                />
                                            )}
                                            <div className="d-flex w-100 my-4">
                                                <Button className="green-btn unclear-btn" type="submit">
                                                    ミッションを登録
                                                </Button>
                                            </div>
                                            <br />
                                            <br />
                                            <br />

                                            <div className="d-flex w-100 mb-4">
                                                <Button
                                                    disabled={!!!id}
                                                    className={`${
                                                        !!!id ? 'green-btn-disabled' : 'green-btn'
                                                    } unclear-btn`}
                                                    onClick={onUnclearSpot}>
                                                    自分を未クリアに変更する
                                                </Button>
                                            </div>

                                            <div className="text-error text-center whitespace-prewrap mb-12">
                                                他ユーザーのミッションも削除可能です。{'\n'}
                                                取り扱いにはご注意ください。
                                            </div>

                                            <div className="d-flex justify-content-center w-100">
                                                <Button
                                                    disabled={!!!id}
                                                    className={`${!!!id ? 'red-btn-disabled' : 'red-btn'}`}
                                                    onClick={handleShowDeleteSpotModal}>
                                                    ミッションを削除
                                                </Button>
                                            </div>
                                        </>
                                    </FrameCover>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>

                <Modal
                    show={isShowDeleteModal}
                    dismissible={true}
                    isHide={true}
                    isHaveBanner={false}
                    onClose={handleCloseModal}
                    children={
                        <ConfirmModal
                            title="ミッションを削除します。"
                            onClose={handleCloseModal}
                            onSubmit={handleShowDeleteSpotModalConfirm}
                        />
                    }
                />
                <Modal
                    isHide={true}
                    show={isShowDeleteConfirmModal}
                    onClose={handleCloseModal}
                    children={
                        <ConfirmModal
                            title={'一度削除すると\nデータ復旧はできません。\n本当によろしいですか？'}
                            onClose={handleCloseModal}
                            onSubmit={onDeleteSpot}
                            closeText="いいえ"
                            submitText="はい"
                        />
                    }
                />
            </div>
        ),
        [
            INITIAL_VALUES,
            SCHEMA,
            handleSubmitData,
            isShowDeleteModal,
            handleCloseModal,
            handleShowDeleteSpotModalConfirm,
            isShowDeleteConfirmModal,
            onDeleteSpot,
            imageSpot,
            handleUploadImage,
            handleChooseLocation,
            categoryList,
            spotClearType.value,
            id,
            data?.spot_id,
            data?.spot_clear_key,
            onUnclearSpot,
            handleShowDeleteSpotModal,
            handleChooseYesOrNoRequired,
            spotCategory.category_id,
            handleChooseCategory,
            spotType?.category_id,
            handleChooseSpotType,
            handleChooseSpotClearType,
        ],
    );
});

export default MySpotRegistDetail;
