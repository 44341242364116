/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    aws_project_region: 'ap-northeast-1',
    // dev
    // Auth: {
    //     identityPoolId: 'ap-northeast-1:f208892a-92c9-46c0-9da5-59e90ed45bae',
    //     identityPoolRegion: 'ap-northeast-1',
    //     // REQUIRED - Amazon Cognito Region
    //     region: 'ap-northeast-1',

    //     // OPTIONAL - Amazon Cognito User Pool ID
    //     userPoolId: 'ap-northeast-1_vdFj6DWel',

    //     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    //     userPoolWebClientId: '1i8hcmtsqdom56oldcsd20vtg',

    //     mandatorySignIn: false,

    //     // OPTIONAL - Hosted UI configuration
    //     oauth: {
    //         domain: 'dev-iriomote-lae-users.auth.ap-northeast-1.amazoncognito.com',
    //         scope: ['profile', 'openid'],
    //         redirectSignIn: ['https://d2kpjw082nkcyt.cloudfront.net?cognito=true'],
    //         redirectSignOut: 'https://localhost',
    //         responseType: 'token',
    //     },
    // },
    // prod
    Auth: {
        identityPoolId: 'ap-northeast-1:f208892a-92c9-46c0-9da5-59e90ed45bae',
        identityPoolRegion: 'ap-northeast-1',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_ih2mVQXwd',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '2l3grf051a6eurr215omr9m40f',

        mandatorySignIn: false,

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'prod-iriomote-lae-users.auth.ap-northeast-1.amazoncognito.com',
            scope: ['profile', 'openid'],
            redirectSignIn: ['https://lae-i.com?cognito=true'],
            redirectSignOut: 'https://localhost',
            responseType: 'token',
        },
    },
};

export default awsmobile;
