import { FrameItem } from 'components';
import { StageActionItem } from 'models';
import { useMemo } from 'react';
import { Environments } from 'configs';
import './StageActionDetailDescribe.scss';
interface StageActionDetailDescribeProps {
    data: StageActionItem;
}

const StageActionDetailDescribe = ({ data }: StageActionDetailDescribeProps): JSX.Element => {
    const des = useMemo(() => {
        return data?.action_comment?.replaceAll('\\n', '\n');
    }, [data]);
    return (
        <div className="mt-10">
            <FrameItem
                children={
                    <div className="actiondetail-describe d-flex flex-column align-items-center">
                        <img className="actiondetail-image" src={Environments.baseUrlWeb + data?.action_photo_url} />
                        <div className="describe">
                            <p className="title">{data?.action_title}</p>
                            <p className="desc">{des}</p>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

export default StageActionDetailDescribe;
